// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images', true)

import '../css/application.scss'

import "@hotwired/turbo-rails"

$(document).ready(function() {
  // Show list Last Updated At dates in user's timezone (not UTC, which might i.e. say it's a day later)
  $('time[data-render-date-in-user-timezone=true]').each(function() {
      var timestamp = $(this).attr('datetime');
      var localeTimestamp = new Date(timestamp).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
      $(this).html(localeTimestamp);
  });
})
